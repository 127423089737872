import React, { useEffect, useState } from 'react';
import './App.css';
import ProgramCard from './components/ProgramCard';
import axios from 'axios';


const PASSWORD = 'manx&334';
const App = () => {
  const [programs, setPrograms] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [inputPassword, setInputPassword] = useState('');


  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get('https://api.jeremiah.business/python-portal/programs');
        console.log(response.data);




        // Define the list of programs to exclude (by name or directory)
        const excludedPrograms = ['Venv',]; // Replace these with actual program names you want to exclude

        const fetchedPrograms = response.data.programs
          .map(program => ({
            name: program.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
            directory: program
          }))
          .filter(program => !excludedPrograms.includes(program.name)); // Filter out the excluded programs

        setPrograms(fetchedPrograms);
      } catch (error) {
        console.error('Failed to fetch programs:', error);
      }
    };

    fetchPrograms();
  }, []);




  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (inputPassword === PASSWORD) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password');
    }
  };
  return !isAuthenticated ? (
    <form onSubmit={handlePasswordSubmit} style={styles.form}>
      <h2>Enter Password</h2>
      <input
        type="password"
        value={inputPassword}
        onChange={(e) => setInputPassword(e.target.value)}
        style={styles.input}
        required
      />
      <button type="submit" style={styles.button}>Submit</button>
    </form>
  ) :
    (
      <div className="container">
        <div className="header">
          <img src="logo.png" alt="Favicon" className="favicon" />
          <h1>Python Portal</h1>
        </div>

        <div className="program-grid">
          {programs.map((program) => (
            <ProgramCard
              key={program.directory}
              title={program.name}
              directory={program.directory}
            />
          ))}
        </div>
      </div>
    );
};


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    padding: '10px',
    margin: '10px 0',
    fontSize: '16px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
  },
};
export default App;

